<!-- 首页页面 -->
<template>
    <div class="home-container">
        <!-- 头部 -->
        <the-header></the-header>
        <!-- 告示区 -->
        <div ref="noticeDom" class="home-notice">
            <div class="home-notice-left">
                <h1>盛易通云平台 V 2.0</h1>
                <p>内容更新中，敬请期待！</p>
                <!-- <p>聚焦产业的供应链金融科技平台，</p>
                <p>让供应链更高效 金融更普惠</p> -->
                <!-- <div class="home-notice-left-btns">
                    <div>了解详情</div>
                    <div>免费注册</div>
                </div> -->
            </div>
            <div class="home-notice-right">
                <img :src="require('@imgs/home/noticepc.png')" alt="" srcset="">
            </div>
        </div>
        <!-- 产品中心 -->
        <div ref="productDom" class="home-wrap" style="margin-top: 739px; background-color: #f3f9ff;">
            <h1 class="home-title">
                产品中心
            </h1>
            <p class="home-subtitle">双驱动·大平台，助力产业科技发展，丰富数</p>
            <ul class="home-product">
                <li v-for="item in productIntroList" :key="item.title" class="home-product-card">
                    <img :src="item.icon" alt="" srcset="">
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.intro }}</p>
                </li>
            </ul>
        </div>
        <!-- 数字化解决方案 -->
        <div ref="digitizingDom" class="home-wrap home-digitizing-bg" style="height: 859px;">
            <h1 class="home-title">
                数字化解决方案
            </h1>
            <p class="home-subtitle">运用数字科技服务实体产业，助长三大行业降低成本，提速效率，模式升级</p>
            <!-- <p class="home-subtitle">运用数字科技服务金融和实体产业，助长三大行业降低成本，提速效率，模式升级</p> -->
            <div class="home-digitizing">
                <ul class="home-digitizing-ul">
                    <li v-for="(item, index) in digitizingIntroList" :key="item.img" class="home-digitizing-li" :class="{ 'active': digitizingIndex === index }" @mouseover="digitizingIndex = index">
                        {{ item.title }}
                    </li>
                </ul>
                <div class="home-digitizing-content">
                    <p v-html="digitizingIntroList[digitizingIndex].intro"></p>
                    <!-- <img :src="require('@imgs/home/digitizing.png')"> -->
                </div>
            </div>
        </div>
        <!-- 行业动态 -->
        <div class="home-wrap home-industry-bg" style="height: 956px;">
            <h1 class="home-title" style="color: #fff;">
                行业动态
            </h1>
            <p class="home-subtitle" style="color: #fff;">行业最新前沿技术分享</p>
            <ul class="home-industry">
                <li v-for="item in industryNewsList" :key="item.title" class="home-industry-li">
                    <div class="home-industry-img">
                        <img :src="item.img" alt="" srcset="" class="zoom-in-and-out">
                    </div>
                    <p class="home-industry-time">{{ item.time }}</p>
                    <h2 class="home-industry-title">
                        {{ item.title }}
                    </h2>
                    <p class="home-industry-intro">{{ item.intro }}</p>
                </li>
            </ul>
        </div>
        <!-- 尾部 -->
        <the-footer></the-footer>
    </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
export default ({
    name: 'Index',
    components: {
        TheHeader,
        TheFooter
    },
    data() {
        return {
            /* 产品中心的介绍 */
            productIntroList: [
                { title: '盛易通云平台', icon: require('@imgs/home/product/1.png'), intro: '内容正在规划中，敬请期待' },
                { title: '智能中登服务', icon: require('@imgs/home/product/2.png'), intro: '内容正在规划中，敬请期待' },
                { title: '发票验真', icon: require('@imgs/home/product/3.png'), intro: '内容正在规划中，敬请期待' },
                { title: 'OCR识别', icon: require('@imgs/home/product/4.png'), intro: '内容正在规划中，敬请期待' },
                { title: '发票易', icon: require('@imgs/home/product/5.png'), intro: '内容正在规划中，敬请期待' },
                { title: '电子签章', icon: require('@imgs/home/product/6.png'), intro: '内容正在规划中，敬请期待' }
            ],
            /* 数字解决方案介绍 */
            digitizingIntroList: [
                { title: '基建工程', intro: '敬请期待' },
                { title: '医药医疗', intro: '敬请期待' },
                { title: '能源化工', intro: '敬请期待' },
                { title: '其他行业', intro: '敬请期待' }
            ],
            digitizingIndex: 0,
            /* 行业动态 */
            industryNewsList: [
                { img: require('@imgs/home/industryNews/1.png'), time: '2022-01-11', title: '标题', intro: '敬请期待' },
                { img: require('@imgs/home/industryNews/2.png'), time: '2022-01-11', title: '标题2', intro: '敬请期待' },
                { img: require('@imgs/home/industryNews/3.png'), time: '2022-01-11', title: '标题3', intro: '敬请期待' }
            ]
        }
    }
})
</script>

<style lang="scss" scoped>
    .home-container {
        text-align: center;
        position: relative;
    }
    .home-notice {
        height: 800px;
        width: 100%;
        position: absolute;
        top: 0;
        background: url(".~@imgs/home/notice.png") no-repeat center;
        background-color: #0d55ff;
        white-space: nowrap;
        .home-notice-left {
            display: inline-block;
            color: #fff;
            text-align: left;
            width: 580px;
            position: relative;
            top: -61px;
            h1 {
                padding-bottom: 30px;
                font-weight: 400;
                font-size: 46px;
            }
            p {
                line-height: 28px;
                font-size: 28px;
                padding-bottom: 14px;
            }
            .home-notice-left-btns {
                margin-top: 38px;
                padding-left: 7px;
                div {
                    display: inline-block;
                    padding: 9px 15px;
                    background-image: linear-gradient(
                        90deg,
                        #ff6f21 0%,
                        #ffa574 100%
                    );
                    border-radius: 20px;
                    width: 110px;
                    text-align: center;
                    margin-right: 20px;
                    cursor: pointer;
                    &:hover {
                        background-image: linear-gradient(
                            90deg,
                            #f7912e 0%,
                            #ffa574 100%
                        );
                    }
                }
            }
        }
        .home-notice-right {
            display: inline-block;
            width: 700px;
            text-align: left;
            position: relative;
            top: 175px;
        }
    }
    .home-wrap {
        height: 1064px;

        .home-title {
            font-size: 40px;
            color: #333333;
            line-height: 56px;
            padding: 100px 0 10px;
        }
        .home-subtitle {
            font-size: 28px;
            color: #666666;
            line-height: 40px;
            padding-bottom: 60px;
        }
    }
    .home-product {
        width: 1200px;
        display: inline-block;
        .home-product-card {
            padding-top: 12px;
            width: 380px;
            background: #ffffff;
            box-shadow: 0 7px 20px 0 rgba(216, 216, 216, 0.7);
            text-align: center;
            margin-right: 30px;
            margin-bottom: 30px;
            h2 {
                color: #333333;
                line-height: 24px;
                padding: 17px 0 20px;
            }
            p {
                color: #9e9e9e;
                line-height: 32px;
                width: 300px;
                display: inline-block;
                padding-bottom: 60px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    .home-digitizing-bg {
        background: url(".~@imgs/home/digitizingbg.png") no-repeat center;
        background-color: #edeeef;
    }
    .home-digitizing {
        width: 1416px;
        height: 500px;
        display: inline-block;
        .home-digitizing-ul {
            width: 250px;
            display: inline-block;
            vertical-align: top;
            .home-digitizing-li {
                display: block;
                line-height: 125px;
                font-size: 28px;
                color: #fff;
                background-color: #3e3e3e;
                cursor: default;
                transition: all 0.4s ease;
            }
            .home-digitizing-li.active {
                background-color: #ff743c;
            }
        }
        .home-digitizing-content {
            display: inline-block;
            width: 1166px;
            height: 500px;
            background: url(".~@imgs/home/digitizing.png") no-repeat center;
            text-align: left;
            padding-top: 100px;
            padding-left: 50px;
            p {
                line-height: 40px;
                font-size: 24px;
                color: #666666;
            }
        }
    }

    .home-industry-bg {
        background: url(".~@imgs/home/industryNews/bg.png") no-repeat center;
        color: #fff;
        background-color: #0e234b;
    }
    .home-industry {
        white-space: nowrap;
        .home-industry-li {
            &:nth-child(-n + 2) {
                margin-right: 30px;
            }
            .home-industry-img {
                display: inline-block;
                overflow: hidden;
            }
            .home-industry-time {
                padding-top: 30px;
                color: #6e737f;
                text-align: left;
            }
            .home-industry-title {
                text-align: left;
                line-height: 60px;
                font-weight: normal;
            }
            .home-industry-intro {
                text-align: left;
                font-size: 18px;
            }
        }
    }
</style>
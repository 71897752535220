<!-- 头部 -->
<template>
    <div class="header-container clearfix">
        <div class="header-left">
            <a class="header-logo">
                盛业
            </a>
            <p v-for="item in routerList" :key="item.path" class="header-router">{{ item.text }}</p>
        </div>
        <div class="header-right">
            <img class="header-phone-icon" :src="require('@imgs/header/phone.png')" alt="" srcset="">
            <p class="header-phone">400-066-9938</p>
        </div>
    </div>
</template>

<script>
export default ({
    name: 'TheHeader',
    data() {
        return {
            routerList: [
                { text: '网站首页', path: '1' },
                { text: '产品服务', path: '2' },
                { text: '解决方案', path: '3' },
                { text: '合作伙伴', path: '4' },
                { text: '服务支持', path: '5' },
                { text: '关于我们', path: '6' },
                { text: '加入我们', path: '7' }
            ]
        }
    }
})
</script>

<style lang="scss" scoped>
    .header-container {
        width: 1400px;
        display: inline-block;
        z-index: 9999;
        color: #fff;
        position: relative;
        line-height: 16px;
    }
    .header-left {
        float: left;
        .header-logo {
            vertical-align: middle;
            margin-right: 45px;
            width: 110px;
            height: 55px;
            color: #000;
            display: inline-block;
            font-size: 0;
            background: url('/logo.svg') no-repeat center 10px;
        }
        .header-router {
            display: inline-block;
            margin-right: 30px;
            padding: 20px 0;
            vertical-align: middle;
        }
    }
    .header-right {
        float: right;
        .header-phone-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
        .header-phone {
            padding: 20px 0;
            vertical-align: middle;
            display: inline-block;
        }
    }
</style>
